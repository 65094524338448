import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBcLVB15kVNh-DcgoesuZ4dMznL8erd8G0",
    authDomain: "colosseo-c08ee.firebaseapp.com",
    projectId: "colosseo-c08ee",
    storageBucket: "colosseo-c08ee.appspot.com",
    messagingSenderId: "413660214057",
    appId: "1:413660214057:web:083a440ca18c166ee3bd38",
    measurementId: "G-RW391CWJ7Z"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// get auth
export const auth = getAuth(app);

// Initialize Cloud Storage and get at reference to the service
export const storage = getStorage(app);